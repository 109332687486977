import * as React from "react";

function SvgIconFocused(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={96.233}
      height={96.234}
      {...props}
    >
      <path
        d="M48.116 96.236a48.117 48.117 0 1148.115-48.119 48.171 48.171 0 01-48.115 48.119zm0-92.313a44.2 44.2 0 1044.2 44.2 44.245 44.245 0 00-44.2-44.202z"
        fill="currentColor"
      />
      <g fill="currentColor">
        <path d="M16.457 37.653q.256.018.7.053v2.7c-.186-.024-.5-.04-.936-.053a1.083 1.083 0 00-.908.291 1.056 1.056 0 00-.25.68v1.07h2.17v2.338h-2.172v10.22H11.77V44.73H9.909v-2.338h1.815v-.81a4.288 4.288 0 01.685-2.8q.722-1.14 3.477-1.139c.21-.007.4-.001.571.01z" />
        <path d="M27.333 53.397a6.985 6.985 0 01-9.746 0 7.861 7.861 0 010-9.522 6.912 6.912 0 019.746 0 7.869 7.869 0 010 9.522zm-2.652-1.831a5.923 5.923 0 000-5.87 2.947 2.947 0 00-4.472 0 5.885 5.885 0 000 5.87 2.938 2.938 0 004.472 0z" />
        <path d="M36.51 46.823a2.943 2.943 0 00-.477-1.279 2.006 2.006 0 00-1.737-.768 2.289 2.289 0 00-2.3 1.664 6.927 6.927 0 00-.325 2.343 6.367 6.367 0 00.325 2.238 2.217 2.217 0 002.239 1.581 1.97 1.97 0 001.655-.627 3.118 3.118 0 00.594-1.627h3.385a5.54 5.54 0 01-1.1 2.861 5.321 5.321 0 01-4.62 2.173 5.41 5.41 0 01-4.506-1.814 7.324 7.324 0 01-1.443-4.7 7.438 7.438 0 011.594-5.076 5.555 5.555 0 014.4-1.815 6.63 6.63 0 013.906 1.071 4.976 4.976 0 011.8 3.779z" />
        <path d="M43.095 42.276v7.64a4.019 4.019 0 00.255 1.628 1.76 1.76 0 001.771.966 2.312 2.312 0 002.314-1.372 4.976 4.976 0 00.324-1.966v-6.9h3.361v12.676h-3.221v-1.791c-.03.039-.108.155-.231.349a2.353 2.353 0 01-.441.512 4.585 4.585 0 01-1.5.953 5.1 5.1 0 01-1.7.257q-2.806 0-3.78-2.023a7.757 7.757 0 01-.545-3.292v-7.64zM54.529 50.905a2.213 2.213 0 00.456 1.256 3.1 3.1 0 002.291.663 3.5 3.5 0 001.56-.291.931.931 0 00.578-.872.942.942 0 00-.466-.849 18.991 18.991 0 00-3.469-1 7.214 7.214 0 01-3.044-1.337 2.9 2.9 0 01-.883-2.28 3.952 3.952 0 011.38-3.018 5.555 5.555 0 013.887-1.262 7.137 7.137 0 013.874.948 4.057 4.057 0 011.72 3.273h-3.315a1.954 1.954 0 00-.361-1.012 2.282 2.282 0 00-1.867-.674 2.692 2.692 0 00-1.545.337.97.97 0 00-.46.791.865.865 0 00.489.825 21.252 21.252 0 003.461.919 6.359 6.359 0 012.97 1.408 3.193 3.193 0 01.976 2.383 3.85 3.85 0 01-1.4 3.076 6.611 6.611 0 01-4.341 1.191 6.541 6.541 0 01-4.422-1.262 4.1 4.1 0 01-1.427-3.215z" />
        <path d="M71.084 42.533a5.153 5.153 0 012.19 1.875 6.234 6.234 0 011.011 2.621 16.292 16.292 0 01.109 2.516h-9.232a3.039 3.039 0 001.325 2.674 3.358 3.358 0 001.827.477 2.575 2.575 0 002.522-1.453h3.384a4.145 4.145 0 01-1.229 2.291 6.156 6.156 0 01-4.768 1.848 6.914 6.914 0 01-4.466-1.559q-1.934-1.56-1.934-5.075a6.865 6.865 0 011.746-5.051 6.112 6.112 0 014.533-1.758 7.217 7.217 0 012.982.594zm-4.959 2.863a3.447 3.447 0 00-.882 1.963h5.71a2.765 2.765 0 00-.883-2 2.9 2.9 0 00-1.966-.682 2.631 2.631 0 00-1.979.719z" />
        <path d="M80.41 42.478a4.038 4.038 0 011.454 1.4v-6.047h3.361v17.118h-3.221v-1.756a4.682 4.682 0 01-1.617 1.64 4.549 4.549 0 01-2.256.511 4.71 4.71 0 01-3.739-1.8 6.9 6.9 0 01-1.518-4.611 7.936 7.936 0 011.494-5.1 4.861 4.861 0 013.995-1.862 4.1 4.1 0 012.047.507zm.814 9.03a4.623 4.623 0 00.732-2.709 4.158 4.158 0 00-1.174-3.326 2.532 2.532 0 00-1.675-.6 2.33 2.33 0 00-2.134 1.1 5.1 5.1 0 00-.68 2.727 5.036 5.036 0 00.693 2.808 2.34 2.34 0 002.1 1.054 2.447 2.447 0 002.138-1.053z" />
      </g>
    </svg>
  );
}

export default SvgIconFocused;
