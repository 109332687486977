import * as React from "react";

function SvgIconAlu(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={95.605}
      height={96.233}
      {...props}
    >
      <path
        d="M25.065 10.841a44 44 0 0152.94 5.7H64.349v3.9h20.438V0h-3.9v13.917a47.808 47.808 0 00-77.9 51.192l3.39-2.051a44 44 0 0118.688-52.217zM92.613 31.741l-3.391 2.048a43.923 43.923 0 01-72.811 45.35h14.061v-3.9H10.039v20.438h3.9V82.169A47.81 47.81 0 0092.62 31.741zm-78.681 47.4h.33l-.33.2z"
        fill="currentColor"
      />
      <g fill="currentColor">
        <path d="M28.322 45.548a9.447 9.447 0 002.371-.52 1.786 1.786 0 001.278-1.687 2 2 0 00-.98-1.926 6.209 6.209 0 00-2.88-.531 3.783 3.783 0 00-3.019 1.042 4.283 4.283 0 00-.844 2.081h-5.726a8.672 8.672 0 011.673-4.892q2.362-3 8.113-3a14.471 14.471 0 016.649 1.478q2.907 1.479 2.907 5.58v10.411q0 1.084.041 2.624a3.293 3.293 0 00.355 1.582 2.126 2.126 0 00.876.684v.874h-6.454a6.6 6.6 0 01-.374-1.29q-.105-.605-.167-1.375a12.254 12.254 0 01-2.849 2.27 8.61 8.61 0 01-4.355 1.1 7.514 7.514 0 01-5.12-1.76 6.255 6.255 0 01-2.021-4.987 6.46 6.46 0 013.251-6.058 14.516 14.516 0 015.243-1.458zm3.629 2.769a6.519 6.519 0 01-1.151.572 9.611 9.611 0 01-1.592.406l-1.351.249a8.9 8.9 0 00-2.727.812 2.711 2.711 0 00-1.4 2.52 2.631 2.631 0 00.855 2.2 3.247 3.247 0 002.082.676 6.2 6.2 0 003.584-1.123q1.638-1.124 1.7-4.1zM49.107 59.352h-5.938V28.663h5.933zM60.809 36.66v13.677a7.193 7.193 0 00.456 2.915 3.152 3.152 0 003.17 1.728 4.142 4.142 0 004.144-2.457 8.944 8.944 0 00.58-3.518V36.66h6.017v22.692h-5.767v-3.206a10.85 10.85 0 00-.415.625 4.167 4.167 0 01-.789.916 8.214 8.214 0 01-2.688 1.707 9.149 9.149 0 01-3.042.458q-5.023 0-6.768-3.623a13.905 13.905 0 01-.975-5.892V36.66z" />
      </g>
    </svg>
  );
}

export default SvgIconAlu;
