import * as React from "react"
import Contact from "src/components/molecule/contact"
import * as styles from "./footer.module.scss";
import {Axonpack} from 'src/data/icon'
import Social from "src/components/molecule/social";
import {IconEmail} from "src/data/icon";
import {IconFax} from "src/data/icon";
import {IconPhone} from "src/data/icon";

const Footer = ({ children }) => {

    return (
            <footer className={styles.footer}>
            <div className={styles.footerTop}>

                <div className={styles.firstContainer}>
                    <h3 className={styles.h3}>
                        contact
                    </h3>
                    <p>
                        <strong>Axonpack Aluminium Tubes</strong>
                        <address>
                            Am Industriepark 8 <br/>
                            9431 St. Stefan <br/>
                            AUSTRIA <br/>
                        </address>
                    </p>
                    <div className={styles.iconContainer}>
                        <IconEmail className={styles.icon} />
                        <div className={styles.iconValue}>
                            info@axonpack.com
                        </div>
                    </div>
                    <div className={styles.iconContainer}>
                        <IconFax className={styles.icon}/>
                        <div className={styles.iconValue}>
                            +43 4352 37488-111
                        </div>
                    </div>
                    <div className={styles.iconContainer}>
                        <IconPhone className={styles.icon}/>
                        <div className={styles.iconValue}>
                            +43 4352 37488-0
                        </div>
                    </div>
                </div>
                <div className={styles.secondContainer}>
                    <div>
                        <h3 className={styles.h3}>About US</h3>
                        <ul>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                        </ul>
                    </div>
                    <div>
                        <h3 className={styles.h3}>About US</h3>
                        <ul>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                        </ul>
                    </div>
                    <div>
                        <h3 className={styles.h3}>About US</h3>
                        <ul>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                        </ul>
                    </div>
                    <div>
                        <h3 className={styles.h3}>About US</h3>
                        <ul>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                        </ul>
                    </div>
                    <div>
                        <h3 className={styles.h3}>About US</h3>
                        <ul>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                        </ul>
                    </div>
                    <div>
                        <h3 className={styles.h3}>About US</h3>
                        <ul>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                            <li>Lorem Ipsum</li>
                        </ul>
                    </div>
                </div>
                <div className={styles.thirdContainer}>
                    <h3 className={styles.h3}>
                        NEWS & EVENTS

                    </h3>
                    <h3 className={styles.h3}>
                        NEWSLETTER ARCHIVE

                    </h3>
                    <h3 className={styles.h3}>
                        DOWNLOADS / LINKS

                    </h3>
                    <h3 className={styles.h3}>
                        CONTACT

                    </h3>
                    <h3 className={styles.h3}>
                        LEGAL DISCLOSURE
                    </h3>
                    <h3 className={styles.h3}>
                        PRIVACY POLICY
                    </h3>





                </div>
            </div>
            <div className={styles.footerBot}>
                <div className={styles.left}>
                    <Axonpack />
                    <p>&copy; {new Date().getFullYear()} AxonPack Aluminium Tubes Contact</p>
                </div>
                <div className={styles.right}>
                    <strong>
                    expect more from <br />
                    aluminium packaging
                    </strong>

                    <Social/>
                </div>
                
            </div>
                
            </footer>

    )
}

export default Footer;
