import * as React from "react";

function SvgIconKaufen(props) {
  return (
    <svg
      id="icon-kaufen_svg__Ebene_1"
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108.25 108.25"
      {...props}
    >
      <defs>
        <style>{".icon-kaufen_svg__cls-1{fill:currentColor}"}</style>
      </defs>
      <g id="icon-kaufen_svg__Group_3331" data-name="Group 3331">
        <path
          id="icon-kaufen_svg__Path_7987"
          data-name="Path 7987"
          className="icon-kaufen_svg__cls-1"
          d="M21 98.58a2.3 2.3 0 01-2.29-2.29v-.17L23 36.35a2.29 2.29 0 012.29-2.13h57.65a2.3 2.3 0 012.3 2.13l4.27 59.77a2.29 2.29 0 01-2.13 2.45H21zM23.5 94h61.25l-3.94-55.19H27.44z"
        />
        <path
          id="icon-kaufen_svg__Path_7988"
          data-name="Path 7988"
          className="icon-kaufen_svg__cls-1"
          d="M69.07 29.2a2.3 2.3 0 01-2.3-2.29 12.65 12.65 0 10-25.3 0 2.29 2.29 0 11-4.58.09v-.09a17.24 17.24 0 0134.47 0 2.3 2.3 0 01-2.29 2.29z"
        />
        <path
          id="icon-kaufen_svg__Path_7989"
          data-name="Path 7989"
          className="icon-kaufen_svg__cls-1"
          d="M39.18 54.82a2.3 2.3 0 01-2.29-2.3V26.91a2.3 2.3 0 014.59 0v25.61a2.31 2.31 0 01-2.3 2.3z"
        />
        <path
          id="icon-kaufen_svg__Path_7990"
          data-name="Path 7990"
          className="icon-kaufen_svg__cls-1"
          d="M69.07 54.82a2.31 2.31 0 01-2.3-2.3V26.91a2.3 2.3 0 014.59 0v25.61a2.3 2.3 0 01-2.29 2.3z"
        />
      </g>
    </svg>
  );
}

export default SvgIconKaufen;
