import * as React from "react";

function SvgIcon70Years(props) {
  return (
    <svg
      id="icon-70-years_svg__Ebene_1"
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108.25 108.25"
      {...props}
    >
      <defs>
        <clipPath id="icon-70-years_svg__clip-path">
          <path fill="none" d="M5.05 5.05h98.16v98.16H5.05z" />
        </clipPath>
        <style>{".icon-70-years_svg__cls-3{fill:currentColor}"}</style>
      </defs>
      <g clipPath="url(#icon-70-years_svg__clip-path)">
        <path
          className="icon-70-years_svg__cls-3"
          d="M54.13 9.05a45.08 45.08 0 1045.08 45.08A45.13 45.13 0 0054.13 9.05m0 94.16a49.08 49.08 0 1149.08-49.08 49.13 49.13 0 01-49.08 49.08"
        />
        <path
          className="icon-70-years_svg__cls-3"
          d="M74 34.67v4.68a50.64 50.64 0 00-5.47 6.94 70.79 70.79 0 00-6 10.67 71.88 71.88 0 00-3.93 10.16q-.87 2.94-2.23 9.5h-5.92a73.14 73.14 0 018.93-24.31A77.67 77.67 0 0168 40.07H44.37v-5.4zM93.05 69.2q2.48-3.69 2.48-13.76a34 34 0 00-1.79-12 6.82 6.82 0 00-7-4.69 7.25 7.25 0 00-6.93 4.45q-2.2 4.45-2.2 13.12a32 32 0 001.41 10.49q2.14 6 7.34 6.05a7.63 7.63 0 006.65-3.7m5.4-28.42q2.85 5.28 2.86 14.45 0 8.7-2.59 14.39-3.68 8.2-12.24 8.2A12.29 12.29 0 0175 71.11q-3.12-5.56-3.13-15a37 37 0 011.88-12.48Q77.26 34 86.47 34q8.28 0 12 6.82M12.52 63.23h.34a4.44 4.44 0 00.74 0 1.34 1.34 0 00.6-.21 1.5 1.5 0 00.43-.66 1.62 1.62 0 00.17-.62l-3.72-10.58H14l2.22 7.47 2.09-7.47h2.81l-3.47 10a14 14 0 01-1.59 3.57 3 3 0 01-2.35.7H12.5zM23.44 53.65a2.75 2.75 0 00-.7 1.57h4.56a2.21 2.21 0 00-.71-1.6 2.32 2.32 0 00-1.59-.54 2.13 2.13 0 00-1.58.57m4-2.28a4.05 4.05 0 011.75 1.49A4.91 4.91 0 0130 55a13.36 13.36 0 01.09 2h-7.42a2.42 2.42 0 001.06 2.13 2.65 2.65 0 001.46.39 2.25 2.25 0 001.47-.52 2.22 2.22 0 00.55-.7h2.7a3.26 3.26 0 01-1 1.83 4.91 4.91 0 01-3.81 1.48 5.52 5.52 0 01-3.57-1.24A4.92 4.92 0 0120 56.33a5.46 5.46 0 011.39-4A4.87 4.87 0 0125 50.89a5.68 5.68 0 012.38.48"
        />
        <path
          className="icon-70-years_svg__cls-3"
          d="M35.11 56.36a3.07 3.07 0 01-.51.26 5.51 5.51 0 01-.71.18l-.6.11a4.08 4.08 0 00-1.22.36 1.22 1.22 0 00-.63 1.13 1.16 1.16 0 00.39 1 1.44 1.44 0 00.93.3 2.77 2.77 0 001.59-.5 2.11 2.11 0 00.76-1.83zm-1.61-1.23a4.18 4.18 0 001-.23.8.8 0 00.57-.76.9.9 0 00-.43-.86 2.92 2.92 0 00-1.29-.23 1.71 1.71 0 00-1.35.46 1.88 1.88 0 00-.37.93h-2.51a3.92 3.92 0 01.75-2.18 4.35 4.35 0 013.62-1.34 6.49 6.49 0 013 .66 2.53 2.53 0 011.29 2.49v5.81a1.44 1.44 0 00.16.71 1 1 0 00.39.31v.39h-2.89a3.08 3.08 0 01-.17-.58c0-.18-.05-.38-.07-.61a5.71 5.71 0 01-1.27 1 3.84 3.84 0 01-1.93.5 3.36 3.36 0 01-2.28-.78 2.8 2.8 0 01-.9-2.23 2.88 2.88 0 011.45-2.7 6.54 6.54 0 012.34-.65z"
        />
        <path
          className="icon-70-years_svg__cls-3"
          d="M43.79 50.92h.23v2.71h-.76a2.19 2.19 0 00-2.15 1 4 4 0 00-.31 1.8v4.84h-2.66V51.16h2.52v1.77a5.71 5.71 0 011.07-1.39 2.94 2.94 0 011.93-.62h.13"
        />
        <path
          className="icon-70-years_svg__cls-3"
          d="M45.72 58.05a1.77 1.77 0 00.36 1 2.48 2.48 0 001.83.53 2.87 2.87 0 001.25-.23.78.78 0 00.09-1.35 15.82 15.82 0 00-2.77-.8 5.65 5.65 0 01-2.43-1.07 2.29 2.29 0 01-.71-1.82 3.17 3.17 0 011.1-2.41 4.46 4.46 0 013.11-1 5.72 5.72 0 013.09.76A3.26 3.26 0 0152 54.25h-2.63a1.56 1.56 0 00-.29-.81 1.82 1.82 0 00-1.49-.54 2.11 2.11 0 00-1.23.27.77.77 0 00-.37.63.7.7 0 00.39.66 16.59 16.59 0 002.76.73 5.14 5.14 0 012.38 1.13 2.55 2.55 0 01.78 1.9 3.08 3.08 0 01-1.13 2.46 5.28 5.28 0 01-3.46 1 5.2 5.2 0 01-3.54-1A3.26 3.26 0 0143 58.05z"
        />
      </g>
    </svg>
  );
}

export default SvgIcon70Years;
