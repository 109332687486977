import * as React from "react"
import * as styles from "./index.module.scss"
import { Link } from "gatsby";
const menuList = ["about us","blue tube","monoture"];

const Nav = ({}) => {
  return (
  <div className={styles.container}>
  {menuList.map(item => {
    return (
      <Link className={styles.link}  to={item.replace(" ","_")}>
        <div className={styles.text} >{item}</div>
      </Link>
    )
  })}
  </div>
)};

export default Nav;