import * as React from "react"
import * as styles from "./index.module.scss"
import {AiFillInstagram} from "@react-icons/all-files/ai/AiFillInstagram"
import {ImYoutube} from "@react-icons/all-files/im/ImYoutube"
import {FaLinkedin} from "@react-icons/all-files/fa/FaLinkedin"
import {IconContext} from "@react-icons/all-files"

const Social = ({siteTitle}) => (
    <IconContext.Provider
        value={{color: "#64CCC9", className: "global-class-name", size: '1rem'}}
    >
        <div className={styles.container}>
            <div className="icon">
                <AiFillInstagram/>
            </div>
            <div className="icon">
                <ImYoutube/>
            </div>
            <div className="icon">
                <FaLinkedin/>
            </div>
        </div>
    </IconContext.Provider>
)

export default Social
