import * as React from "react";

function SvgIconMarkt(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={107.654}
      height={92.846}
      {...props}
    >
      <g fill="currentColor">
        <path d="M54.023 74.838h-.197a28.413 28.413 0 010-56.825h.135a28.41 28.41 0 01.219 56.817zm-.227-53.034a24.622 24.622 0 00.029 49.243h.273a24.619 24.619 0 00-.152-49.235z" />
        <path d="M29.096 35.133h49.468v3.791H29.096zM29.096 53.927h49.468v3.791H29.096z" />
        <path d="M51.455 74.131c-23.478-29.1-.439-55.231-.2-55.491l2.813 2.542-1.406-1.271 1.407 1.269c-.87.969-21.074 24.023.34 50.57z" />
        <path d="M55.305 74.131l-2.951-2.381c9.019-11.18 11.816-23.081 8.314-35.372a42.044 42.044 0 00-7.972-15.2l2.813-2.542c.235.264 23.273 26.39-.204 55.495z" />
      </g>
      <g fill="currentColor">
        <path d="M107.296 53.013l-10.043 8.182-7.8-9.577a.965.965 0 01.822-1.572l16.487 1.256a.966.966 0 01.534 1.711z" />
        <path d="M99.938 54.694l-3.734-.655A42.824 42.824 0 0038.119 6.846l-1.407-3.521a46.616 46.616 0 0163.226 51.369z" />
        <g>
          <path d="M.295 44.501l9.292-9.027 8.608 8.861a.965.965 0 01-.681 1.638l-16.535.186a.965.965 0 01-.684-1.658z" />
          <path d="M53.942 92.846A46.587 46.587 0 017.48 42.188l3.778.327a42.824 42.824 0 0061.976 41.951l1.709 3.384a46.537 46.537 0 01-21.001 4.996z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconMarkt;
