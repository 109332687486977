import * as React from "react";

function SvgIconTm(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={98.161}
      height={98.161}
      {...props}
    >
      <g fill="currentColor">
        <path d="M49.081 98.161a49.081 49.081 0 1149.08-49.081 49.136 49.136 0 01-49.08 49.081zm0-94.161a45.081 45.081 0 1045.08 45.08A45.132 45.132 0 0049.081 4z" />
        <path d="M13.208 38.152v-6.411H41.62v6.411H31.227V66.42h-7.624V38.152zM56.337 31.741l8.111 23.849h.1l7.674-23.849h10.735V66.42h-7.144V41.843h-.1l-8.5 24.577h-5.874l-8.5-24.332h-.1V66.42h-7.135V31.741z" />
      </g>
    </svg>
  );
}

export default SvgIconTm;
