// extracted by mini-css-extract-plugin
export var color0 = "footer-module--color0--69+n5";
export var color1 = "footer-module--color1--B00qJ";
export var color2 = "footer-module--color2--l9Czx";
export var color3 = "footer-module--color3--6Bpws";
export var color4 = "footer-module--color4--b7Eg0";
export var color5 = "footer-module--color5--nRIJn";
export var footer = "footer-module--footer--zcCBH";
export var footerTop = "footer-module--footerTop--7zC9a";
export var firstContainer = "footer-module--firstContainer--+rSrg";
export var iconContainer = "footer-module--iconContainer--TGmgA";
export var iconValue = "footer-module--iconValue--xEX5I";
export var icon = "footer-module--icon--Zh+I-";
export var secondContainer = "footer-module--secondContainer--3jS36";
export var h3 = "footer-module--h3--Aayza";
export var thirdContainer = "footer-module--thirdContainer--zsumI";