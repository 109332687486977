import * as React from "react";

function SvgIconOffice(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40.241}
      height={44.478}
      {...props}
    >
      <path
        d="M26.397 44.478H.98a.98.98 0 01-.98-.98V.98A.98.98 0 01.98 0h25.417a.98.98 0 01.98.98v42.518a.98.98 0 01-.98.98zM1.96 42.518h23.457V1.96H1.96z"
        fill="currentColor"
      />
      <path
        d="M39.265 44.478H26.397a.98.98 0 01-.98-.98V11.746a.98.98 0 01.98-.98h12.868a.98.98 0 01.98.98v31.753a.98.98 0 01-.98.979zm-11.884-1.96h10.9V12.722h-10.9z"
        fill="currentColor"
      />
      <g fill="currentColor">
        <path d="M35.729 20.156h-5.8a.98.98 0 110-1.96h5.8a.98.98 0 110 1.96zM35.729 25.787h-5.8a.98.98 0 110-1.96h5.8a.98.98 0 110 1.96zM35.729 31.418h-5.8a.98.98 0 110-1.96h5.8a.98.98 0 110 1.96zM35.729 37.049h-5.8a.98.98 0 110-1.96h5.8a.98.98 0 010 1.96z" />
      </g>
      <g fill="currentColor">
        <path d="M6.276 9.83a.98.98 0 01-.98-.98V6.21a.98.98 0 011.96 0v2.64a.98.98 0 01-.98.98zM11.218 9.83a.98.98 0 01-.98-.98V6.21a.98.98 0 011.96 0v2.64a.98.98 0 01-.98.98zM16.159 9.83a.98.98 0 01-.98-.98V6.21a.98.98 0 011.96 0v2.64a.98.98 0 01-.98.98zM21.1 9.83a.98.98 0 01-.98-.98V6.21a.98.98 0 011.96 0v2.64a.979.979 0 01-.98.98zM6.276 17.184a.98.98 0 01-.98-.98v-2.64a.98.98 0 111.96 0v2.64a.98.98 0 01-.98.98zM11.218 17.184a.98.98 0 01-.98-.98v-2.64a.98.98 0 111.96 0v2.64a.98.98 0 01-.98.98zM16.159 17.184a.98.98 0 01-.98-.98v-2.64a.98.98 0 111.96 0v2.64a.98.98 0 01-.98.98zM21.1 17.184a.98.98 0 01-.98-.98v-2.64a.98.98 0 111.96 0v2.64a.979.979 0 01-.98.98z" />
        <g>
          <path d="M6.276 24.539a.98.98 0 01-.98-.98v-2.641a.98.98 0 011.96 0v2.641a.98.98 0 01-.98.98zM11.218 24.539a.98.98 0 01-.98-.98v-2.641a.98.98 0 011.96 0v2.641a.98.98 0 01-.98.98zM16.159 24.539a.98.98 0 01-.98-.98v-2.641a.98.98 0 011.96 0v2.641a.98.98 0 01-.98.98zM21.1 24.539a.98.98 0 01-.98-.98v-2.641a.98.98 0 111.96 0v2.641a.98.98 0 01-.98.98z" />
        </g>
        <g>
          <path d="M6.276 31.893a.98.98 0 01-.98-.98v-2.641a.98.98 0 011.96 0v2.643a.98.98 0 01-.98.978zM11.218 31.893a.98.98 0 01-.98-.98v-2.641a.98.98 0 011.96 0v2.643a.98.98 0 01-.98.978zM16.159 31.893a.98.98 0 01-.98-.98v-2.641a.98.98 0 011.96 0v2.643a.98.98 0 01-.98.978zM21.1 31.893a.98.98 0 01-.98-.98v-2.641a.98.98 0 111.96 0v2.643a.98.98 0 01-.98.978z" />
        </g>
        <g>
          <path d="M6.276 39.248a.98.98 0 01-.98-.98v-2.64a.98.98 0 111.96 0v2.641a.98.98 0 01-.98.979zM11.218 39.248a.98.98 0 01-.98-.98v-2.64a.98.98 0 011.96 0v2.641a.98.98 0 01-.98.979zM16.159 39.248a.98.98 0 01-.98-.98v-2.64a.98.98 0 011.96 0v2.641a.98.98 0 01-.98.979zM21.1 39.248a.98.98 0 01-.98-.98v-2.64a.98.98 0 111.96 0v2.641a.98.98 0 01-.98.979z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconOffice;
