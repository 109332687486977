import * as React from "react";

function SvgIconProduktion(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={85.169}
      height={84.389}
      {...props}
    >
      <path
        d="M69.971 65.383a4.087 4.087 0 01-2.845-1.147l-5.606-5.4a1.9 1.9 0 112.63-2.73l5.606 5.4a.312.312 0 00.44-.008l3.872-4.018a.308.308 0 00-.008-.439l-5.471-5.273a1.9 1.9 0 01-.574-2.618 22.939 22.939 0 003.256-8.294 1.9 1.9 0 012.2-1.527l7.6-.139a.311.311 0 00.3-.317l-.1-5.579a.3.3 0 00-.095-.217.41.41 0 00-.22-.087l-7.6.139a1.894 1.894 0 01-2.261-1.443 22.939 22.939 0 00-3.559-8.17 1.9 1.9 0 01.48-2.637l5.272-5.47a.306.306 0 00.088-.221.309.309 0 00-.1-.218l-4.018-3.873a.327.327 0 00-.222-.087.3.3 0 00-.216.095l-5.272 5.469a1.9 1.9 0 01-2.619.578 22.925 22.925 0 00-8.295-3.257 1.9 1.9 0 01-1.527-2.205l-.139-7.6a.345.345 0 00-.317-.3l-5.578.1a.309.309 0 00-.3.316l.138 7.6a1.894 1.894 0 01-1.443 2.258 22.946 22.946 0 00-8.168 3.559 1.9 1.9 0 01-2.637-.477l-5.47-5.274a.313.313 0 00-.44.009l-3.872 4.018a.306.306 0 00-.088.221.309.309 0 00.1.218l5.47 5.273a1.9 1.9 0 11-2.043 3.192l-.158-.1a1.768 1.768 0 01-.293-.231l-5.606-5.4a4.1 4.1 0 01-.107-5.8l3.871-4.017a4.106 4.106 0 015.8-.107l4.661 4.493a26.632 26.632 0 016.617-2.883l-.11-6.475A4.107 4.107 0 0145.02.107l5.579-.1a4.074 4.074 0 014.177 4.024l.118 6.475a26.668 26.668 0 016.719 2.638l4.493-4.661a4.1 4.1 0 015.8-.106l4.018 3.873a4.1 4.1 0 01.107 5.8l-4.492 4.66a26.608 26.608 0 012.882 6.616l6.475-.118a4.1 4.1 0 014.176 4.025l.1 5.579a4.106 4.106 0 01-4.03 4.171l-6.473.118a26.647 26.647 0 01-2.638 6.72l4.66 4.492a4.1 4.1 0 01.106 5.8l-3.872 4.018a4.093 4.093 0 01-2.954 1.252z"
        fill="currentColor"
      />
      <path
        d="M54.194 50.021a1.9 1.9 0 01-.849-3.591 10.882 10.882 0 10-14.33-15.119 1.9 1.9 0 01-3.3-1.875 14.675 14.675 0 1119.321 20.385 1.9 1.9 0 01-.842.2z"
        fill="currentColor"
      />
      <path
        d="M28.155 84.389h-3.9a3.442 3.442 0 01-3.438-3.438v-4.1a19.193 19.193 0 01-4-1.658l-2.9 2.9a3.44 3.44 0 01-4.861 0l-2.762-2.76a3.442 3.442 0 010-4.863l2.9-2.9a19.258 19.258 0 01-1.658-4H3.443a3.443 3.443 0 01-3.438-3.439v-3.9a3.442 3.442 0 013.438-3.438h4.094a19.223 19.223 0 011.658-4l-2.9-2.9a3.442 3.442 0 010-4.863l2.762-2.761a3.519 3.519 0 014.862 0l2.9 2.894a19.244 19.244 0 014-1.658V35.42a3.442 3.442 0 013.438-3.438h3.9a3.442 3.442 0 013.438 3.438v4.094a19.18 19.18 0 014 1.659l2.9-2.9a3.521 3.521 0 014.864 0l2.759 2.76a3.441 3.441 0 010 4.862l-2.9 2.9a19.124 19.124 0 011.659 4h4.094a3.442 3.442 0 013.438 3.438v3.9a3.443 3.443 0 01-3.438 3.439h-4.094a19.138 19.138 0 01-1.659 4l2.9 2.9a3.441 3.441 0 010 4.862l-2.76 2.761a3.442 3.442 0 01-4.863 0l-2.9-2.9a19.176 19.176 0 01-4 1.658v4.1a3.442 3.442 0 01-3.44 3.436zm-3.551-3.791h3.2v-4.91a1.9 1.9 0 011.5-2.171 15.47 15.47 0 005.554-2.3 1.9 1.9 0 012.594.477l3.474 3.475 2.26-2.262-3.471-3.472a1.9 1.9 0 01-.48-2.6 15.465 15.465 0 002.3-5.554 1.9 1.9 0 012.17-1.5h4.911v-3.2h-4.911a1.9 1.9 0 01-2.17-1.5 15.458 15.458 0 00-2.3-5.554 1.9 1.9 0 01.479-2.594l3.472-3.473-2.26-2.262-3.473 3.473a1.9 1.9 0 01-2.595.478 15.486 15.486 0 00-5.554-2.3 1.9 1.9 0 01-1.5-2.17v-4.91h-3.2v4.91a1.9 1.9 0 01-1.5 2.17 15.489 15.489 0 00-5.555 2.3 1.895 1.895 0 01-2.6-.481l-3.47-3.469-2.262 2.261 3.475 3.474a1.9 1.9 0 01.475 2.592 15.467 15.467 0 00-2.3 5.554 1.894 1.894 0 01-2.171 1.5h-4.91v3.2h4.91a1.894 1.894 0 012.171 1.5 15.475 15.475 0 002.3 5.554 1.9 1.9 0 01-.477 2.593l-3.474 3.474 2.261 2.261 3.472-3.471a1.9 1.9 0 012.594-.48 15.472 15.472 0 005.556 2.3 1.9 1.9 0 011.5 2.171z"
        fill="currentColor"
      />
      <path
        d="M26.203 69.021a10.835 10.835 0 1110.836-10.835 10.846 10.846 0 01-10.836 10.835zm0-17.879a7.044 7.044 0 107.045 7.044 7.052 7.052 0 00-7.045-7.044z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgIconProduktion;
