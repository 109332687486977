import * as React from "react";

function SvgIconMitarbeiter(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={86.6}
      height={93.381}
      {...props}
    >
      <g fill="currentColor">
        <path d="M69.801 38.543h-2.539l-4.666-8.393 3.313-1.842 3.583 6.444h.308v.555l.233.419-.233.13z" />
        <path d="M77.181 88.303H62.423L60.2 58.676l-1.914-3.857 3.395-1.687 2.244 4.523 2.015 26.857h7.724l2.015-26.857 5.454-10.975c2.333-4.574 2.69-10.523-1.716-13.609l-3.246-1.416-3.829 6.888h-2.539v-2.687l-.233-.13.233-.419v-.555h.308l4.419-7.949 6.867 3.029c5.519 3.711 6.8 11.345 3.116 18.565-.092.181-.148.281-.148.281l-4.961 10zM69.798 27.092a9.241 9.241 0 119.241-9.241 9.251 9.251 0 01-9.241 9.241zm0-14.691a5.45 5.45 0 105.45 5.451 5.456 5.456 0 00-5.45-5.451z" />
      </g>
      <g fill="currentColor">
        <path d="M19.338 38.543h-2.539v-2.687l-.233-.13.233-.419v-.555h.308l3.583-6.444 3.314 1.842z" />
        <path d="M24.178 88.303H9.42L7.197 58.676 2.209 48.625a3.73 3.73 0 01-.122-.234c-3.682-7.214-2.4-14.849 3.118-18.559l.3-.164 6.567-2.865 4.419 7.949h.308v.555l.233.419-.233.13v2.687h-2.538l-3.83-6.888-3.245 1.416c-4.407 3.086-4.051 9.035-1.72 13.6l.09.172 5.366 10.808 2.015 26.857h7.724l2.015-26.857 2.245-4.523 3.4 1.687-1.915 3.857zM16.8 27.092a9.241 9.241 0 119.241-9.241 9.252 9.252 0 01-9.241 9.241zm0-14.691a5.45 5.45 0 105.45 5.451 5.457 5.457 0 00-5.45-5.451z" />
      </g>
      <g fill="currentColor">
        <path d="M51.666 93.38H34.88l-2.6-34.719-5.922-11.93a7.868 7.868 0 01-.138-.264c-5.006-9.812-1.818-17.856 3.563-21.474l.3-.164 7.466-3.258 5.163 9.286h1.13l5.163-9.286 7.765 3.422c5.381 3.619 8.571 11.662 3.564 21.474-.109.215-.174.33-.174.33l-5.889 11.867zm-13.269-3.791h9.752l2.4-31.95 6.408-12.9c2.823-5.533 3.233-12.757-2.167-16.51l-4.146-1.809-4.573 8.225h-5.59l-4.574-8.225-4.146 1.809c-5.4 3.753-4.989 10.977-2.167 16.511l.113.214 6.3 12.683zM43.274 21.131a10.566 10.566 0 1110.565-10.566 10.578 10.578 0 01-10.565 10.566zm0-17.34a6.775 6.775 0 106.774 6.775 6.783 6.783 0 00-6.774-6.775z" />
      </g>
    </svg>
  );
}

export default SvgIconMitarbeiter;
