import * as React from "react";

function SvgIconPhone(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40.887}
      height={40.887}
      {...props}
    >
      <path
        d="M29.692 31.716A20.546 20.546 0 019.171 11.195a2.025 2.025 0 012.024-2.024h4.046a2.026 2.026 0 012.024 2.024 12.279 12.279 0 00.616 3.855 2.034 2.034 0 01-.5 2.065l-1.696 2.256a13.39 13.39 0 005.832 5.83l2.348-1.772a1.951 1.951 0 011.984-.406 12.282 12.282 0 003.846.612 2.026 2.026 0 012.024 2.024v4.035a2.029 2.029 0 01-2.027 2.022zM11.195 10.905a.292.292 0 00-.29.29 18.809 18.809 0 0018.787 18.786.294.294 0 00.29-.29v-4.035a.294.294 0 00-.29-.29 14 14 0 01-4.4-.7.288.288 0 00-.293.068l-2.879 2.183a.863.863 0 01-.917.08 15.441 15.441 0 01-7.315-7.315.871.871 0 01.082-.916l2.1-2.787c.2-.208.172-.343.154-.4a13.994 13.994 0 01-.7-4.386.294.294 0 00-.29-.29z"
        fill="#64ccc9"
        stroke="#64ccc9"
      />
      <path
        d="M20.443 40.387a19.943 19.943 0 1119.944-19.944.867.867 0 01-1.734 0 18.209 18.209 0 10-18.21 18.21c6.044 0 11.916-3.075 13.96-7.313a1.894 1.894 0 00.116-1.916c-.5-.739-1.885-1.129-3.614-1.013a.867.867 0 01-.114-1.731c2.416-.147 4.3.489 5.166 1.774a3.47 3.47 0 01.007 3.642c-2.316 4.804-8.844 8.291-15.521 8.291z"
        fill="#64ccc9"
        stroke="#64ccc9"
      />
    </svg>
  );
}

export default SvgIconPhone;
