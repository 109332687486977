import * as React from "react";

function SvgIconSolutions(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={96.234}
      height={96.234}
      {...props}
    >
      <path
        d="M48.117 96.236a48.117 48.117 0 1148.117-48.119 48.172 48.172 0 01-48.117 48.119zm0-92.313a44.2 44.2 0 1044.2 44.2 44.245 44.245 0 00-44.2-44.202z"
        fill="currentColor"
      />
      <g fill="currentColor">
        <path d="M29.986 31.75v2.509h-4.238v11.657h-2.979V34.259h-4.258V31.75z" />
        <path d="M29.748 31.75h3.008v8.7a4.93 4.93 0 00.345 2.131 2.878 2.878 0 004.664 0 4.915 4.915 0 00.345-2.131v-8.7h3.009v8.708a7.306 7.306 0 01-.7 3.518q-1.3 2.307-4.979 2.307t-4.99-2.307a7.307 7.307 0 01-.7-3.518zM52.56 33.259a3.6 3.6 0 01.651 2.163 3.155 3.155 0 01-.657 2.085 3.391 3.391 0 01-1.08.808 3.158 3.158 0 011.636 1.249 3.744 3.744 0 01.552 2.075 4.144 4.144 0 01-.63 2.26 3.9 3.9 0 01-2.6 1.825 9.792 9.792 0 01-2 .192h-6.383V31.75h6.843a4.342 4.342 0 013.668 1.509zm-7.692.952v3.123h3.443a2.84 2.84 0 001.5-.351 1.34 1.34 0 00.575-1.245 1.268 1.268 0 00-.761-1.307 5.345 5.345 0 00-1.674-.221zm0 5.468v3.776h3.439a3.363 3.363 0 001.435-.249 1.785 1.785 0 00.93-1.769 1.521 1.521 0 00-.9-1.519 3.571 3.571 0 00-1.417-.24zM64.717 34.259h-7.5v3.008h6.882v2.461h-6.882v3.642h7.843v2.547H54.328V31.75h10.389z" />
        <path d="M67.664 45.916h-3.44l4.555-7.217-4.332-6.949h3.537l2.526 4.614 2.6-4.614h3.421l-4.335 6.833 4.6 7.333h-3.594l-2.692-4.83zM15.147 59.758a1.832 1.832 0 00.377 1.038 2.555 2.555 0 001.892.548 2.9 2.9 0 001.289-.241.768.768 0 00.478-.721.78.78 0 00-.384-.7 15.827 15.827 0 00-2.867-.826 5.946 5.946 0 01-2.516-1.1 2.394 2.394 0 01-.73-1.883 3.263 3.263 0 011.141-2.494 4.591 4.591 0 013.213-1.043 5.9 5.9 0 013.2.783 3.35 3.35 0 011.421 2.706h-2.739a1.627 1.627 0 00-.3-.837 1.884 1.884 0 00-1.542-.557 2.226 2.226 0 00-1.277.278.8.8 0 00-.38.654.714.714 0 00.4.682 17.42 17.42 0 002.86.76 5.248 5.248 0 012.454 1.162 2.64 2.64 0 01.807 1.971 3.179 3.179 0 01-1.16 2.542 6.973 6.973 0 01-7.242-.058 3.386 3.386 0 01-1.18-2.657z" />
        <path d="M30.739 61.821a5.772 5.772 0 01-8.055 0 6.5 6.5 0 010-7.869 5.711 5.711 0 018.055 0 6.5 6.5 0 010 7.869zm-2.192-1.514a4.888 4.888 0 000-4.851 2.433 2.433 0 00-3.695 0 4.856 4.856 0 000 4.851 2.426 2.426 0 003.695 0z" />
        <path d="M34.854 63.102h-2.739V48.936h2.739zM38.287 52.627v6.314a3.319 3.319 0 00.211 1.346 1.456 1.456 0 001.463.8 1.913 1.913 0 001.914-1.134 4.127 4.127 0 00.268-1.624v-5.7h2.78v10.473H42.26v-1.479q-.038.049-.191.288a1.913 1.913 0 01-.365.423 3.8 3.8 0 01-1.241.788 4.209 4.209 0 01-1.4.212 3.127 3.127 0 01-3.123-1.673 6.4 6.4 0 01-.451-2.72v-6.314z" />
        <path d="M44.537 54.673v-1.951h1.461V49.8h2.71v2.922h1.7v1.951h-1.7v5.535a1.252 1.252 0 00.164.8 1.81 1.81 0 001 .158h.264c.092 0 .184-.008.274-.015v2.047l-1.3.049a3.487 3.487 0 01-2.653-.674 2 2 0 01-.461-1.451v-6.449z" />
        <path d="M53.014 51.376h-2.777v-2.528h2.777zm-2.777 1.25h2.777v10.475h-2.777z" />
        <path d="M62.436 61.821a5.772 5.772 0 01-8.055 0 6.5 6.5 0 010-7.869 5.711 5.711 0 018.055 0 6.5 6.5 0 010 7.869zm-2.192-1.514a4.888 4.888 0 000-4.851 2.433 2.433 0 00-3.695 0 4.856 4.856 0 000 4.851 2.426 2.426 0 003.695 0z" />
        <path d="M72.202 53.227a3.469 3.469 0 011.028 2.821v7.054h-2.807V56.73a2.928 2.928 0 00-.219-1.268 1.543 1.543 0 00-1.527-.808 1.893 1.893 0 00-1.9 1.182 4.08 4.08 0 00-.268 1.6v5.67H63.77v-10.46h2.653v1.528a4.645 4.645 0 011-1.163 3.453 3.453 0 012.134-.634 4.02 4.02 0 012.645.85z" />
        <path d="M75.932 59.758a1.832 1.832 0 00.378 1.038 2.554 2.554 0 001.892.548 2.9 2.9 0 001.289-.241.769.769 0 00.478-.721.779.779 0 00-.385-.7 15.774 15.774 0 00-2.866-.826 5.946 5.946 0 01-2.516-1.1 2.394 2.394 0 01-.73-1.883 3.263 3.263 0 011.141-2.494 4.591 4.591 0 013.213-1.043 5.9 5.9 0 013.2.783 3.349 3.349 0 011.421 2.706h-2.739a1.628 1.628 0 00-.3-.837 1.885 1.885 0 00-1.543-.557 2.224 2.224 0 00-1.276.278.8.8 0 00-.38.654.714.714 0 00.4.682 17.418 17.418 0 002.86.76 5.248 5.248 0 012.454 1.162 2.641 2.641 0 01.807 1.971 3.179 3.179 0 01-1.16 2.542 6.973 6.973 0 01-7.242-.058 3.386 3.386 0 01-1.18-2.657z" />
      </g>
    </svg>
  );
}

export default SvgIconSolutions;
