import * as React from "react";

function SvgIconFax(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={44.5}
      height={44.5}
      {...props}
    >
      <g fill="#64ccc9" stroke="#64ccc9" strokeWidth={1.5}>
        <path d="M16.507 34.44a3.535 3.535 0 01-3.531-3.53V16.471a3.531 3.531 0 117.061 0V30.91a3.535 3.535 0 01-3.53 3.53zm0-20.657a2.69 2.69 0 00-2.687 2.688V30.91a2.688 2.688 0 005.375 0V16.471a2.69 2.69 0 00-2.688-2.689z" />
        <path d="M13.625 32.491h-3.178a.421.421 0 01-.422-.422V15.313a.421.421 0 01.422-.422h3.178a.422.422 0 01.391.579 2.669 2.669 0 00-.2 1v14.441a2.676 2.676 0 00.2 1 .421.421 0 01-.391.579zm-2.758-.843h2.188a3.455 3.455 0 01-.08-.738V16.472a3.455 3.455 0 01.08-.738h-2.187zM34.055 32.491H19.388a.421.421 0 01-.391-.579 2.676 2.676 0 00.2-1v-14.44a2.669 2.669 0 00-.2-1 .422.422 0 01.391-.579h14.667a.421.421 0 01.422.422V32.07a.421.421 0 01-.422.421zm-14.1-.843h13.678V15.734H19.962a3.455 3.455 0 01.08.738v14.439a3.455 3.455 0 01-.08.737z" />
        <path d="M31.63 22.11h-9.59a.421.421 0 01-.422-.422v-3.794a.421.421 0 01.422-.422h9.591a.421.421 0 01.422.422v3.794a.421.421 0 01-.423.422zm-9.169-.843h8.748v-2.951h-8.748zM23.673 24.113H22.04a.422.422 0 110-.843h1.634a.422.422 0 110 .843zM27.652 24.113h-1.634a.422.422 0 010-.843h1.634a.422.422 0 110 .843zM31.631 24.113h-1.634a.422.422 0 010-.843h1.634a.422.422 0 010 .843zM23.673 26.115H22.04a.422.422 0 110-.843h1.634a.422.422 0 110 .843zM27.652 26.115h-1.634a.422.422 0 010-.843h1.634a.422.422 0 110 .843zM31.631 26.115h-1.634a.422.422 0 010-.843h1.634a.422.422 0 010 .843zM23.673 28.116H22.04a.422.422 0 110-.843h1.634a.422.422 0 110 .843zM27.652 28.116h-1.634a.422.422 0 110-.843h1.634a.422.422 0 110 .843zM31.631 28.116h-1.634a.422.422 0 110-.843h1.634a.422.422 0 110 .843zM31.63 15.733h-9.59a.421.421 0 01-.422-.422v-4.83a.421.421 0 01.422-.422h7.98a.421.421 0 01.3.124l1.611 1.611a.421.421 0 01.124.3v3.22a.421.421 0 01-.425.419zm-9.169-.843h8.748v-2.624l-1.364-1.364h-7.384z" />
        <path d="M29.575 13.318h-5.48a.422.422 0 010-.843h5.48a.422.422 0 010 .843z" />
        <path d="M22.25 43.75a21.5 21.5 0 1121.5-21.5 21.525 21.525 0 01-21.5 21.5zm0-42.157A20.657 20.657 0 1042.907 22.25 20.68 20.68 0 0022.25 1.593z" />
      </g>
    </svg>
  );
}

export default SvgIconFax;
