import * as React from "react";

function SvgIconEmail(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={39.887}
      height={39.887}
      {...props}
    >
      <path
        d="M19.943 0a19.943 19.943 0 1019.943 19.943A19.963 19.963 0 0019.943 0zm0 2.546a17.4 17.4 0 11-17.4 17.4 17.378 17.378 0 0117.4-17.4zM8.911 10.608a2.992 2.992 0 00-2.97 2.97v12.73a2.992 2.992 0 002.97 2.97h22.065a2.992 2.992 0 002.97-2.97v-12.73a2.992 2.992 0 00-2.97-2.97zm0 2.546h22.065a.4.4 0 01.424.424v12.73a.4.4 0 01-.424.424H8.911a.4.4 0 01-.424-.424v-12.73a.4.4 0 01.424-.424zm2.374.835a1.269 1.269 0 00-.7 2.2l3.859 3.633-3.885 3.885a1.273 1.273 0 101.8 1.79l3.925-3.925 1.419 1.339a3.214 3.214 0 004.455 0l1.432-1.353 3.938 3.938a1.273 1.273 0 101.8-1.79l-3.885-3.885 3.859-3.62a1.276 1.276 0 10-1.75-1.856l-7.134 6.71a1.139 1.139 0 01-.968 0l-7.121-6.71a1.407 1.407 0 00-1.048-.358z"
        fill="#64ccc9"
      />
    </svg>
  );
}

export default SvgIconEmail;
