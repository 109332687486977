import * as React from "react";
import * as styles from "./header.module.scss";
import Social from "src/components/molecule/social";
import Nav from "../molecule/nav";
import {Axonpack} from 'src/data/icon'

const Header = ({siteTitle}) => (
    <header className={styles.lvl1}>
        <div className={styles.left_lvl1}>
            <Axonpack/>
        </div>
        <div className={styles.right_lvl1}>
            <div className={styles.lvl2}>
                <div className={styles.up_lvl2}>
                    <Social/>
                </div>
                <div className={styles.bot_lvl2}>
                    <Nav/>
                </div>
            </div>
        </div>
    </header>
)

export default Header;
