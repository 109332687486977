import * as React from "react";

function SvgIconFabrik(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={37.802}
      height={43.979}
      {...props}
    >
      <path
        d="M37.802 43.98H.002V21.617l13.889-8.058v5.458l9.409-5.458v7.633h14.5zM1.777 42.203h34.249V22.969h-14.5v-6.325l-9.409 5.458v-5.458l-10.336 6z"
        fill="currentColor"
      />
      <path
        d="M35.927 22.906h-10L28.559 0h4.739zm-8.011-1.777h6.018L31.71 1.776h-1.571z"
        fill="currentColor"
      />
      <g fill="currentColor">
        <path d="M8.703 26.364h2.743v5.465H8.703zM17.713 26.364h2.743v5.465h-2.743zM26.357 26.364H29.1v5.465h-2.743z" />
      </g>
      <g fill="currentColor">
        <path d="M8.703 34.101h2.743v5.465H8.703zM17.713 34.101h2.743v5.465h-2.743zM26.357 34.101H29.1v5.465h-2.743z" />
      </g>
    </svg>
  );
}

export default SvgIconFabrik;
