import * as React from "react";

function SvgIconSeven(props) {
  return (
    <svg
      id="icon-seven_svg__Ebene_1"
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 108.25 108.25"
      {...props}
    >
      <defs>
        <style>{".icon-seven_svg__cls-1{fill:currentColor}"}</style>
      </defs>
      <g id="icon-seven_svg__Group_3333" data-name="Group 3333">
        <g id="icon-seven_svg__Group_3332" data-name="Group 3332">
          <g id="icon-seven_svg__Group_33" data-name="Group 33">
            <path
              className="icon-seven_svg__cls-1"
              d="M54.15 89.81H54A28.42 28.42 0 0154 33h.14a28.41 28.41 0 01.22 56.82zm-.23-53A24.63 24.63 0 0054 86h.27a24.62 24.62 0 00-.15-49.24z"
              id="icon-seven_svg__Path_2279"
              data-name="Path 2279"
            />
            <g id="icon-seven_svg__Group_32" data-name="Group 32">
              <path
                className="icon-seven_svg__cls-1"
                d="M29.22 50.1h49.47v3.79H29.22z"
                id="icon-seven_svg__Rectangle_18"
                data-name="Rectangle 18"
              />
              <path
                className="icon-seven_svg__cls-1"
                d="M29.22 68.9h49.47v3.79H29.22z"
                id="icon-seven_svg__Rectangle_19"
                data-name="Rectangle 19"
              />
            </g>
            <path
              className="icon-seven_svg__cls-1"
              d="M51.58 89.1c-23.48-29.1-.44-55.23-.2-55.49l2.81 2.54-1.4-1.27 1.41 1.27c-.87 1-21.08 24 .34 50.57z"
              id="icon-seven_svg__Path_2280"
              data-name="Path 2280"
            />
            <path
              className="icon-seven_svg__cls-1"
              d="M55.43 89.1l-3-2.38c9-11.18 11.82-23.08 8.31-35.37a41.86 41.86 0 00-8-15.2l2.82-2.54c.31.26 23.35 26.39-.13 55.49z"
              id="icon-seven_svg__Path_2281"
              data-name="Path 2281"
            />
          </g>
        </g>
        <path
          className="icon-seven_svg__cls-1"
          d="M53.63 22.13a7.5 7.5 0 117.5-7.5 7.51 7.51 0 01-7.5 7.5zm0-11a3.5 3.5 0 103.5 3.5 3.5 3.5 0 00-3.5-3.5z"
          id="icon-seven_svg__Ellipse_142"
          data-name="Ellipse 142"
        />
        <path
          className="icon-seven_svg__cls-1"
          d="M18.63 38.13a7.5 7.5 0 117.5-7.5 7.51 7.51 0 01-7.5 7.5zm0-11a3.5 3.5 0 103.5 3.5 3.5 3.5 0 00-3.5-3.5z"
          id="icon-seven_svg__Ellipse_143"
          data-name="Ellipse 143"
        />
        <path
          className="icon-seven_svg__cls-1"
          d="M89.63 38.13a7.5 7.5 0 117.5-7.5 7.51 7.51 0 01-7.5 7.5zm0-11a3.5 3.5 0 103.5 3.5 3.5 3.5 0 00-3.5-3.5z"
          id="icon-seven_svg__Ellipse_144"
          data-name="Ellipse 144"
        />
        <path
          className="icon-seven_svg__cls-1"
          d="M8.63 69.13a7.5 7.5 0 117.5-7.5 7.51 7.51 0 01-7.5 7.5zm0-11a3.5 3.5 0 103.5 3.5 3.5 3.5 0 00-3.5-3.5z"
          id="icon-seven_svg__Ellipse_145"
          data-name="Ellipse 145"
        />
        <path
          className="icon-seven_svg__cls-1"
          d="M99.63 69.13a7.5 7.5 0 117.5-7.5 7.51 7.51 0 01-7.5 7.5zm0-11a3.5 3.5 0 103.5 3.5 3.5 3.5 0 00-3.5-3.5z"
          id="icon-seven_svg__Ellipse_146"
          data-name="Ellipse 146"
        />
        <path
          className="icon-seven_svg__cls-1"
          d="M18.63 101.13a7.5 7.5 0 117.5-7.5 7.51 7.51 0 01-7.5 7.5zm0-11a3.5 3.5 0 103.5 3.5 3.5 3.5 0 00-3.5-3.5z"
          id="icon-seven_svg__Ellipse_147"
          data-name="Ellipse 147"
        />
        <path
          className="icon-seven_svg__cls-1"
          d="M89.63 101.13a7.5 7.5 0 117.5-7.5 7.51 7.51 0 01-7.5 7.5zm0-11a3.5 3.5 0 103.5 3.5 3.5 3.5 0 00-3.5-3.5z"
          id="icon-seven_svg__Ellipse_148"
          data-name="Ellipse 148"
        />
      </g>
    </svg>
  );
}

export default SvgIconSeven;
